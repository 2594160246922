import React from 'react';
import { Link } from 'react-router-dom';
import './footer.styles.css';
import Logo from '../../assets/logo.png';

const Footer = () => {
  return (
    <footer className='footer'>
      <div className='footer-content'>
        <div className='footer-top'>
          <img src={Logo} alt='Logo' className='footer-logo' />
          <nav className='footer-nav'>
            <ul>
              <li>
                <Link to='/'>Home</Link>
              </li>
              <li>
                <Link to='/testinfo'>Test Menu</Link>
              </li>
              <li>
                <Link to='/about'>About</Link>
              </li>
              <li>
                <Link to='/contact'>Contact</Link>
              </li>
              <li>
                <Link to='/careers'>Careers</Link>
              </li>
              <li>
                <a href='https://sierragenomics.labnexus.net'>Results Portal</a>
              </li>
            </ul>
          </nav>
        </div>
        <div className='footer-bottom'>
          <div className='footer-copyright'>
            &copy; 2024 Sierra Genomics All Rights Reserved
          </div>
          {/* <div className='footer-privacy'>
            <a href='#privacy-policy'>Privacy Policy</a>
          </div> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
