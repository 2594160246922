import React, { useState, useEffect } from 'react';
import { Link, NavLink } from 'react-router-dom';
import './nav.styles.css';
import logo from '../../assets/logo.png';
import { FiChevronDown, FiMenu } from 'react-icons/fi';
import MobileNav from './MobileNav';

const Navbar = () => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isShrunk, setIsShrunk] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleScroll = () => {
    if (window.scrollY > 50) {
      setIsShrunk(true);
    } else {
      setIsShrunk(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className={`nav-container ${isShrunk ? 'shrink' : ''}`}>
      <div className={`navbar ${isShrunk ? 'shrink' : ''}`}>
        <div className='navbar-start'>
          <Link to='/'>
            <img src={logo} alt='company logo' id='main-logo' />
          </Link>
        </div>

        <div className='navbar-end'>
          <ul className='navbar-list'>
            <li>
              <NavLink to='/' className='nav-item'>
                Home
              </NavLink>
            </li>
            <li>
              <NavLink to='/about' className='nav-item'>
                About
              </NavLink>
            </li>
            <li
              className='nav-item nav-dropdown'
              onMouseEnter={toggleDropdown}
              onMouseLeave={toggleDropdown}
            >
              Test Menu <FiChevronDown className='arrow-icon ml-2' />
              {dropdownOpen && (
                <ul className='dropdown-menu'>
                  <li className='menu-item'>
                    <NavLink to='/testinfo/1' className='nav-link'>
                      UTI PCR Testing
                    </NavLink>
                  </li>
                  <li className='menu-item'>
                    <NavLink to='/testinfo/2' className='nav-link'>
                      Respiratory PCR Testing
                    </NavLink>
                  </li>
                </ul>
              )}
            </li>
            <li>
              <NavLink to='/contact' className='nav-item'>
                Contact
              </NavLink>
            </li>
            <li>
              <NavLink to='/careers' className='nav-item'>
                Careers
              </NavLink>
            </li>
            <li>
              <a
                href='https://sierragenomics.labnexus.net'
                target='_blank'
                rel='noopener noreferrer'
                className='nav-item nav-btn'
              >
                Results Portal
              </a>
            </li>
            <li>
              <Link to='/contact' className='nav-item'>
                <div className='primary-btn contact-btn btn'>Contact Us</div>
              </Link>
            </li>
          </ul>
          <button className='menu-icon' onClick={toggleMobileMenu}>
            <FiMenu size={30} />
          </button>
        </div>
      </div>
      <MobileNav isOpen={isMobileMenuOpen} toggleMenu={toggleMobileMenu} />
    </div>
  );
};

export default Navbar;
