export const slideIn = (direction, delay) => {
    return {
      hidden: {
        y: direction === 'up' ? 40 : direction === 'down' ? -40 : 0,
        x: direction === 'left' ? 40 : direction === 'right' ? -100 : 0,
        opacity: 0,  // Start with opacity 0
      },
      visible: {
        y: 0,
        x: 0,
        opacity: 1,  // Transition to opacity 1
        transition: {
          type: 'tween',
          duration: 1,
          delay: delay,
          ease: [0.25, 0.25, 0.25, 0.75],
        },
      },
    };
  };

  export const slideInFromMiddle = (delay) => {
    return {
      hidden: {
        x: '-5vw', // Start from the middle of the screen (50% of the viewport width)
        opacity: 0, // Start with opacity 0
      },
      visible: {
        x: 0,
        opacity: 1, // Transition to opacity 1
        transition: {
          type: 'tween',
          duration: 1,
          delay: delay,
          ease: [0.25, 0.25, 0.25, 0.75],
        },
      },
    };
  };


  export const fadeIn = (delay) => {
    return {
      hidden: {
        opacity: 0,  // Start with opacity 0
      },
      visible: {
        opacity: 1,  // Transition to opacity 1
        transition: {
          type: 'tween',
          duration: 1,
          delay: delay,
          ease: [0.25, 0.25, 0.25, 0.75],
        },
      },
    };
  };