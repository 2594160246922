import React from 'react';
import { useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { slideIn, fadeIn } from '../variants';
import '../PageStyles/TestDetails.styles.css';
import SimpleContact from '../components/Forms/SimpleContact';
import testData from '../assets/TestData';

const TestDetails = () => {
  const { testId } = useParams();
  const test = testData.find((test) => test.id === parseInt(testId));

  if (!test) {
    return <div>Test not found</div>;
  }
  return (
    <div className='main-container'>
      <div className='test-details-container'>
        <div className='details-header'>
          <motion.h1
            variants={slideIn('up', 0.1)}
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true }}
          >
            {test.title}
          </motion.h1>
        </div>
        <div className='details-section'>
          <img
            src={test.thumbnailImg}
            alt='description'
            className='about-image'
          />
          <div className='details-text'>
            <motion.h1
              variants={slideIn('up', 0.1)}
              initial='hidden'
              whileInView='visible'
              viewport={{ once: true }}
              className='details-subheader'
            >
              About
            </motion.h1>
            <motion.p
              variants={slideIn('up', 0.1)}
              initial='hidden'
              whileInView='visible'
              viewport={{ once: true }}
              className='details-body'
            >
              {test.description}
            </motion.p>
          </div>
        </div>

        <div className='details-section'>
          <img src={test.accentImg} alt='description' className='about-image' />
          <div className='details-text'>
            <motion.h1
              variants={slideIn('up', 0.1)}
              initial='hidden'
              whileInView='visible'
              viewport={{ once: true }}
              className='details-subheader'
            >
              Benefits
            </motion.h1>
            <motion.p
              variants={slideIn('up', 0.1)}
              initial='hidden'
              whileInView='visible'
              viewport={{ once: true }}
              className='details-body'
            >
              {test.benefits}
            </motion.p>
          </div>
        </div>

        <div className='details-section'>
          <img
            src={test.accentImg2}
            alt='description'
            className='about-image'
          />
          <div className='details-text'>
            <motion.h1
              variants={slideIn('up', 0.1)}
              initial='hidden'
              whileInView='visible'
              viewport={{ once: true }}
              className='details-subheader'
            >
              {test.reasonSubheader}
            </motion.h1>
            <motion.p
              variants={slideIn('up', 0.1)}
              initial='hidden'
              whileInView='visible'
              viewport={{ once: true }}
              className='details-body'
            >
              {test.reason}
            </motion.p>
          </div>
        </div>
      </div>

      <div className='cta-section'>
        <div className='cta-grid-item'>
          <motion.h1
            variants={slideIn('up', 0.1)}
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true }}
            className='section-header mb-3'
          >
            Get Started Today
          </motion.h1>
        </div>
        <div className='cta-grid-item cta-content'>
          <h2 className='cta-subheader mb-3'>
            For more information about our tests or to inquire about our
            services,
            <br />
            please contact us
          </h2>
          <div className='cta-btn-container'>
            <Link to='/about' className='btn secondary-btn'>
              Learn More
            </Link>
            <Link to='/contact' className='btn primary-btn'>
              Contact Us
            </Link>
          </div>
        </div>
      </div>

      <motion.div
        variants={fadeIn(0.1)}
        initial='hidden'
        whileInView='visible'
        viewport={{ once: true }}
        className='bottom-contact'
      >
        <SimpleContact />
      </motion.div>
    </div>
  );
};

export default TestDetails;
