import React from 'react';
import { motion } from 'framer-motion';
import { slideIn } from '../../variants';

const StatCard = () => {
  return (
    <div className='benefits-grid'>
      <div className='b-grid-item'>
        <img
          src='https://res.cloudinary.com/dh8uixj4v/image/upload/v1721442632/icon1-01_v00a0x.png'
          alt='benefits'
          className='benefits-img'
        />
        <motion.h1
          variants={slideIn('up', 0.1)}
          initial='hidden'
          whileInView='visible'
          viewport={{ once: false, amount: 0.5 }}
          className='b-header'
        >
          98% Accurate
        </motion.h1>
        <motion.p
          variants={slideIn('up', 0.1)}
          initial='hidden'
          whileInView='visible'
          viewport={{ once: false, amount: 0.5 }}
          className='body-text '
        >
          Ensures highly reliable results that healthcare professionals can
          depend on.
        </motion.p>
      </div>
      <div className='b-grid-item'>
        <img
          src='https://res.cloudinary.com/dh8uixj4v/image/upload/v1721442737/icon2-02_qchef1.png'
          alt='benefits'
          className='benefits-img'
        />
        <motion.h1
          variants={slideIn('up', 0.1)}
          initial='hidden'
          whileInView='visible'
          viewport={{ once: false, amount: 0.5 }}
          className='b-header '
        >
          Fast Turnaround
        </motion.h1>
        <motion.p
          variants={slideIn('up', 0.1)}
          initial='hidden'
          whileInView='visible'
          viewport={{ once: false, amount: 0.5 }}
          className='body-text '
        >
          Delivers clear and reliable results within hours, compared to days or
          weeks with culture methods.
        </motion.p>
      </div>
      <div className='b-grid-item'>
        <img
          src='https://res.cloudinary.com/dh8uixj4v/image/upload/v1721442712/icon3-03_yrzdzf.png'
          alt='benefits'
          className='benefits-img'
        />
        <motion.h1
          variants={slideIn('up', 0.1)}
          initial='hidden'
          whileInView='visible'
          viewport={{ once: false, amount: 0.5 }}
          className='b-header '
        >
          60% More Accurate Than Culture Testing
        </motion.h1>
        <motion.p
          variants={slideIn('up', 0.1)}
          initial='hidden'
          whileInView='visible'
          viewport={{ once: false, amount: 0.5 }}
          className='body-text '
        >
          PCR testing is more accurate than traditional culture methods,
          ensuring precise diagnostics.
        </motion.p>
      </div>
      <div className='b-grid-item'>
        <img
          src='https://res.cloudinary.com/dh8uixj4v/image/upload/v1721442671/icon4-04_cd9ajn.png'
          alt='benefits'
          className='benefits-img'
        />
        <motion.h1
          variants={slideIn('up', 0.1)}
          initial='hidden'
          whileInView='visible'
          viewport={{ once: false, amount: 0.5 }}
          className='b-header '
        >
          Targeted Treatment
        </motion.h1>
        <motion.p
          variants={slideIn('up', 0.1)}
          initial='hidden'
          whileInView='visible'
          viewport={{ once: false, amount: 0.5 }}
          className='body-text '
        >
          Identifies specific pathogens and antibiotic resistance markers,
          enabling tailored and effective treatment plans.
        </motion.p>
      </div>
    </div>
  );
};

export default StatCard;
