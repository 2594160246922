import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { slideIn, fadeIn } from '../variants';
import '../PageStyles/About.styles.css';
import SimpleContact from '../components/Forms/SimpleContact';

const About = () => {
  return (
    <div className='main-container'>
      <div className='ap-container'>
        <motion.div
          variants={slideIn('up', 0.1)}
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true }}
          className='about-header-section'
        >
          <h1 className='ap-section-header mb-3 '>About Us</h1>
        </motion.div>

        <motion.div
          variants={slideIn('up', 0.1)}
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true }}
          className='about-header-img'
        >
          <img
            src='https://res.cloudinary.com/dh8uixj4v/image/upload/v1720582220/shutterstock_1912524370_xgekjn.jpg'
            alt='about-hero'
            loading='lazy'
          />
        </motion.div>

        <div className='ap-content'>
          <motion.div
            variants={slideIn('up', 0.1)}
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true }}
            className='ap-main-text'
          >
            <p className='ap-section-body text-primary'>
              Welcome to Sierra Genomics, where we are at the forefront of
              molecular diagnostics. Our mission is to provide unparalleled
              accuracy and speed in detecting infections through our advanced
              Molecular Polymerase Chain Reaction (PCR) testing. By leveraging
              the power of molecular biology, we ensure precise and reliable
              diagnostic solutions that enhance patient care and treatment
              outcomes.
            </p>
          </motion.div>
          <div className='ap-text '>
            <motion.h2
              variants={slideIn('up', 0.1)}
              initial='hidden'
              whileInView='visible'
              viewport={{ once: true }}
              className='about-subheader'
            >
              Our Commitment to Excellence
            </motion.h2>
            <motion.p
              variants={slideIn('up', 0.1)}
              initial='hidden'
              whileInView='visible'
              viewport={{ once: true }}
              className='section-body text-primary'
            >
              At Sierra Genomics, we understand the critical importance of
              accurate diagnostics in healthcare. That's why we have dedicated
              ourselves to developing and delivering state-of-the-art PCR
              testing solutions. Our advanced methods identify the genetic
              material of pathogens, ensuring precise identification of
              infections even in cases where traditional tests may fall short.
            </motion.p>
          </div>
          <div className='ap-text-list '>
            <motion.h2
              variants={slideIn('up', 0.1)}
              initial='hidden'
              whileInView='visible'
              viewport={{ once: true }}
              className='about-subheader'
            >
              Benefits of PCR Testing at Sierra Genomics
            </motion.h2>
            <ul>
              <motion.li
                variants={slideIn('up', 0.1)}
                initial='hidden'
                whileInView='visible'
                viewport={{ once: true }}
                className='section-body'
              >
                <strong>High Accuracy:</strong> Our Molecular PCR testing is
                renowned for its exceptional accuracy. By targeting specific
                genetic sequences of pathogens, we ensure precise
                identification, enabling accurate diagnoses and appropriate
                treatments. This precision is vital for effective patient care.
              </motion.li>
              <motion.li
                variants={slideIn('up', 0.1)}
                initial='hidden'
                whileInView='visible'
                viewport={{ once: true }}
                className='section-body'
              >
                <strong>Rapid Results:</strong> Time is of the essence in
                healthcare, and our PCR testing provides quick turnaround times.
                Unlike conventional diagnostic methods that may take days, our
                PCR testing can deliver definitive results within hours. This
                speed is crucial for making timely decisions that significantly
                impact patient outcomes.
              </motion.li>
              <motion.li
                variants={slideIn('up', 0.1)}
                initial='hidden'
                whileInView='visible'
                viewport={{ once: true }}
                className='section-body'
              >
                <strong>Sensitivity and Specificity:</strong> Our PCR testing
                offers high sensitivity and specificity, minimizing the chances
                of false positives or negatives. This reliability ensures that
                healthcare providers receive accurate data for effective
                treatment planning. With Sierra Genomics, patients receive the
                right treatment promptly, reducing the risk of complications.
              </motion.li>
            </ul>
          </div>
          <div className='ap-text '>
            <motion.h2
              variants={slideIn('up', 0.1)}
              initial='hidden'
              whileInView='visible'
              viewport={{ once: true }}
              className='about-subheader'
            >
              Why Choose Sierra Genomics?
            </motion.h2>
            <motion.p
              variants={slideIn('up', 0.1)}
              initial='hidden'
              whileInView='visible'
              viewport={{ once: true }}
              className='section-body text-primary'
            >
              At Sierra Genomics, our vision is to revolutionize diagnostics
              through innovative molecular testing solutions. We strive to
              provide healthcare professionals with the tools they need to make
              informed decisions, ensuring better patient outcomes and advancing
              medical science.
            </motion.p>
            <motion.p
              variants={slideIn('up', 0.1)}
              initial='hidden'
              whileInView='visible'
              viewport={{ once: true }}
              className='section-body text-primary'
            >
              Sierra Genomics stands at the forefront of modern diagnostics,
              offering unmatched accuracy, speed, and reliability. By detecting
              the genetic material of pathogens, we ensure precise
              identification of infections, facilitating informed and timely
              treatment decisions. As healthcare continues to evolve, Sierra
              Genomics is dedicated to playing an increasingly vital role in
              improving patient outcomes and advancing medical science.
            </motion.p>
          </div>
          <div className='about-btn-container'>
            <Link to='/contact' className='btn primary-btn mt-5'>
              Get Started Today
            </Link>
          </div>
        </div>

        <motion.div
          variants={slideIn('up', 0.1)}
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true }}
          className='certifications'
        >
          <div className='cert-header'>
            <h2 className='cert-subheader mb-3'>Our Certifications</h2>
          </div>
          <div className='cert-content'>
            <div className='certification-images'>
              <img
                src='https://i.ibb.co/ZKLPwqp/CMSLogo.png'
                alt='iso-9001'
                className='certification-image'
              />
            </div>
          </div>
        </motion.div>
      </div>

      <motion.div
        variants={fadeIn(0.1)}
        initial='hidden'
        whileInView='visible'
        viewport={{ once: true }}
        className='bottom-contact'
      >
        <SimpleContact />
      </motion.div>
    </div>
  );
};

export default About;
