import React from 'react';
import './nav.styles.css';

import Navbar from './Navbar';

const Navigation = () => {
  return (
    <div className='navigation-wrapper'>
      <Navbar />
    </div>
  );
};

export default Navigation;
