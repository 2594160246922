const testData = [
  {
    id: 1,
    title: 'Urinary Tract Infection (UTI) Testing',
    subtitle: 'With Antibiotic Resistance Profiling',
    summary: 'Our UTI PCR Test is a highly accurate, rapid diagnostic tool that detects urinary tract infections using advanced polymerase chain reaction technology. It identifies bacterial DNA in urine samples, offering precise results. This test surpasses traditional culture methods in speed and sensitivity, providing timely information for effective treatment.',
    description:
      'Our Molecular UTI PCR Test is a highly accurate and rapid diagnostic tool designed to detect urinary tract infections. Utilizing advanced polymerase chain reaction technology, this test identifies the presence of bacterial DNA in urine samples, ensuring precise results. It surpasses traditional culture methods in both speed and sensitivity, providing healthcare professionals with timely information for effective treatment.',
    benefits:
      'Enhanced diagnostic accuracy is a key advantage of the UTI PCR Test, as it detects bacterial DNA directly from urine samples. This high level of precision helps in reducing false negatives, ensuring that infections are identified promptly. The test also identifies a wide range of bacteria, including those that are difficult to culture, providing comprehensive detection critical for managing complex or recurrent infections effectively. Additionally, results are typically available within hours, enabling healthcare professionals to make timely and informed treatment decisions. The swift turnaround time can significantly improve patient outcomes and reduce the duration of symptoms.',
    reasonSubheader: 'When to Consider UTI PCR Testing',
    reason:
      'The UTI PCR Test is particularly beneficial for patients experiencing recurrent or complicated urinary tract infections. It is valuable for cases where initial treatments have failed, as it can identify less common bacterial strains. Indications for ordering this test include persistent and severe urinary frequency, urgency, and dysuria in patients. Consider this test for patients presenting with lower abdominal pain, hematuria, or foul-smelling urine. Additionally, it is ideal for patients with a history of recurrent UTIs or those with underlying conditions such as diabetes. The test requires a urine sample, which is then analyzed in our lab for comprehensive results.',
    sample:
      'This test requires a urine sample, which can be collected at home or in a healthcare setting, and sent to our lab for analysis.',
    thumbnailImg:
      'https://res.cloudinary.com/dh8uixj4v/image/upload/v1720069396/shutterstock_2264924965_soiapw.jpg',
    accentImg:
      'https://res.cloudinary.com/dh8uixj4v/image/upload/v1720072144/shutterstock_1318893791_1_ish8jn.jpg',
    accentImg2:
      'https://res.cloudinary.com/dh8uixj4v/image/upload/v1720209531/shutterstock_1462382504_xvnvt0.jpg',
  },
  {
    id: 2,
    title: 'Respiratory Pathogen Testing',
    subtitle: 'Respiratory testing for infections',
    summary: 'Our PCR Respiratory Test accurately identifies bacteria in respiratory samples and provides a detailed antibiotic resistance profile. It detects both common and rare pathogens, enabling precise antibiotic selection. This test delivers rapid, reliable results to support informed treatment decisions, improving patient outcomes and reducing infection duration.',
    description:
     'Our state-of-the-art Molecular PCR Respiratory Test not only accurately identifies bacteria in respiratory samples, such as sputum or bronchoalveolar lavage fluid, but also provides a comprehensive analysis of their antibiotic resistance profiles. This advanced diagnostic tool ensures precise detection of both common and rare pathogens, facilitating the selection of the most effective antibiotics. By delivering rapid and reliable results, our test supports healthcare professionals in making informed, tailored treatment decisions, ultimately improving patient outcomes and reducing the duration of respiratory infections.',
    benefits:
      'Healthcare professionals can achieve optimized treatment outcomes with quick and reliable diagnostic results. By identifying specific bacteria and their resistance profiles, it reduces the need for broad-spectrum antibiotics, minimizing the risk of antibiotic resistance. This test is particularly beneficial for patients with resistant respiratory infections or those at high risk. Prompt and precise detection ensures effective treatment, shorter illness duration, and fewer complications, while supporting responsible antibiotic use and overall public health.',
    reasonSubheader: 'When to Consider Respiratory Pathogen Testing',
    reason:
      'Patients exhibiting symptoms such as persistent cough, difficulty breathing, and chest discomfort should consider this diagnostic test. Individuals with chronic respiratory diseases like chronic obstructive pulmonary disease (COPD) or cystic fibrosis, who are more susceptible to respiratory infections, are also advised to undergo testing. Healthcare providers may recommend this test to confirm a respiratory infection, tailor antibiotic treatment, or monitor the effectiveness of ongoing therapy. The test involves collecting a sample via a nasal swab, which is then sent to our lab for comprehensive analysis.',
    sample:
      'This test requires a respiratory nasal swab sample, which can be collected at home or in a healthcare setting. The sample is then sent to our laboratory for analysis.',
    thumbnailImg:
      'https://res.cloudinary.com/dh8uixj4v/image/upload/v1720069426/shutterstock_770461312_lo56yt.jpg',
    accentImg:
      'https://res.cloudinary.com/dh8uixj4v/image/upload/v1720207766/shutterstock_1430165429_mtz7yv.jpg',
    accentImg2:
      'https://res.cloudinary.com/dh8uixj4v/image/upload/v1720209485/shutterstock_1372845869_b2g2b8.jpg',
  },
];

export default testData;
