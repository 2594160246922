import React from 'react';
import { Link } from 'react-router-dom';

const SimpleContact = () => {
  return (
    <div className='simple-contact-section'>
      <Link to='/contact'>
        <h2>Contact Us</h2>
      </Link>
      <h3>
        If you have any questions or would like to learn more about our
        services, please contact us:
      </h3>
      <p>Email: contactus@sierra-genomics.com</p>
      <p>Phone: (214) 954-7483</p>
      <p>6500 Greenville Ave, Suite 640, Dallas, TX 75206</p>
    </div>
  );
};

export default SimpleContact;
