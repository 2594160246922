import React from 'react';
import { motion } from 'framer-motion';
import { slideIn, fadeIn } from '../variants';
import CareerContact from '../components/Forms/CareerContact';
import SimpleContact from '../components/Forms/SimpleContact';
import '../PageStyles/Career.styles.css';

const Careers = () => {
  return (
    <div className='main-container'>
      <div className='career-container'>
        <div className='career-hero'>
          <motion.div
            variants={slideIn('right', 0.1)}
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true }}
            className='career-header'
          >
            <h1>Careers</h1>
            <h2 className='career-subheader'>
              Join Our Team of Dedicated Professionals
            </h2>
            <p>
              Are you driven by a passion for healthcare and a commitment to
              excellence? We want to hear from you!
            </p>
          </motion.div>
        </div>
        <motion.div
          variants={slideIn('up', 0.1)}
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true }}
          className='career-form-container'
        >
          <CareerContact />
        </motion.div>
      </div>
      <motion.div
        variants={fadeIn(0.1)}
        initial='hidden'
        whileInView='visible'
        viewport={{ once: true }}
        className='bottom-contact'
      >
        <SimpleContact />
      </motion.div>
    </div>
  );
};

export default Careers;
