import React, { useState } from 'react';
import emailjs from '@emailjs/browser';
import '../../PageStyles/Career.styles.css';

const CareerContact = () => {
  const states = [
    { name: 'Alabama', abbreviation: 'AL' },
    { name: 'Alaska', abbreviation: 'AK' },
    { name: 'Arizona', abbreviation: 'AZ' },
    { name: 'Arkansas', abbreviation: 'AR' },
    { name: 'California', abbreviation: 'CA' },
    { name: 'Colorado', abbreviation: 'CO' },
    { name: 'Connecticut', abbreviation: 'CT' },
    { name: 'Delaware', abbreviation: 'DE' },
    { name: 'Florida', abbreviation: 'FL' },
    { name: 'Georgia', abbreviation: 'GA' },
    { name: 'Hawaii', abbreviation: 'HI' },
    { name: 'Idaho', abbreviation: 'ID' },
    { name: 'Illinois', abbreviation: 'IL' },
    { name: 'Indiana', abbreviation: 'IN' },
    { name: 'Iowa', abbreviation: 'IA' },
    { name: 'Kansas', abbreviation: 'KS' },
    { name: 'Kentucky', abbreviation: 'KY' },
    { name: 'Louisiana', abbreviation: 'LA' },
    { name: 'Maine', abbreviation: 'ME' },
    { name: 'Maryland', abbreviation: 'MD' },
    { name: 'Massachusetts', abbreviation: 'MA' },
    { name: 'Michigan', abbreviation: 'MI' },
    { name: 'Minnesota', abbreviation: 'MN' },
    { name: 'Mississippi', abbreviation: 'MS' },
    { name: 'Missouri', abbreviation: 'MO' },
    { name: 'Montana', abbreviation: 'MT' },
    { name: 'Nebraska', abbreviation: 'NE' },
    { name: 'Nevada', abbreviation: 'NV' },
    { name: 'New Hampshire', abbreviation: 'NH' },
    { name: 'New Jersey', abbreviation: 'NJ' },
    { name: 'New Mexico', abbreviation: 'NM' },
    { name: 'New York', abbreviation: 'NY' },
    { name: 'North Carolina', abbreviation: 'NC' },
    { name: 'North Dakota', abbreviation: 'ND' },
    { name: 'Ohio', abbreviation: 'OH' },
    { name: 'Oklahoma', abbreviation: 'OK' },
    { name: 'Oregon', abbreviation: 'OR' },
    { name: 'Pennsylvania', abbreviation: 'PA' },
    { name: 'Rhode Island', abbreviation: 'RI' },
    { name: 'South Carolina', abbreviation: 'SC' },
    { name: 'South Dakota', abbreviation: 'SD' },
    { name: 'Tennessee', abbreviation: 'TN' },
    { name: 'Texas', abbreviation: 'TX' },
    { name: 'Utah', abbreviation: 'UT' },
    { name: 'Vermont', abbreviation: 'VT' },
    { name: 'Virginia', abbreviation: 'VA' },
    { name: 'Washington', abbreviation: 'WA' },
    { name: 'West Virginia', abbreviation: 'WV' },
    { name: 'Wisconsin', abbreviation: 'WI' },
    { name: 'Wyoming', abbreviation: 'WY' },
  ];

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    city: '',
    state: '',
    position: '',
    years_experience: '',
    sales_style: '',
    question_a: '',
    question_b: '',
    message: '',
  });

  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [buttonText, setButtonText] = useState('Submit');
  const [validationErrors, setValidationErrors] = useState({});

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    const errors = {};

    // Email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      errors.email = 'Please enter a valid email address';
    }

    // Phone number validation: Ensure it has 10 digits
    const cleanedPhone = formData.phone.replace(/\D/g, '');
    if (cleanedPhone.length !== 10) {
      errors.phone = 'Phone number must be 10 digits';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setButtonText('Sending...');

    emailjs
      .sendForm(
        'service_vuasfxq',
        'template_79sd2oi',
        e.target,
        'U4mqa74Td0Ym-Q2Y7'
      )
      .then(
        (result) => {
          setSuccessMessage(
            "Thanks for your submission, we'll get back to you shortly."
          );
          setErrorMessage('');
          setButtonText('Submit');
          setFormData({
            firstName: '',
            lastName: '',
            email: '',
            phone: '',
            city: '',
            state: '',
            position: '',
            years_experience: '',
            sales_style: '',
            question_a: '',
            question_b: '',
            message: '',
          });
        },
        (error) => {
          setErrorMessage(
            'There was an error submitting your form, please try again.'
          );
          setSuccessMessage('');
          setButtonText('Submit');
        }
      );
  };

  return (
    <div className='career-form'>
      <form onSubmit={handleSubmit}>
        <div className='c-form-row'>
          <div className='c-form-group'>
            <label htmlFor='firstName'>First Name</label>
            <input
              type='text'
              id='firstName'
              name='firstName'
              value={formData.firstName}
              onChange={handleChange}
              autoComplete='given-name'
            />
          </div>
          <div className='c-form-group'>
            <label htmlFor='lastName'>Last Name</label>
            <input
              type='text'
              id='lastName'
              name='lastName'
              value={formData.lastName}
              onChange={handleChange}
              autoComplete='family-name'
            />
          </div>
        </div>
        <div className='c-form-row'>
          <div className='c-form-group'>
            <label htmlFor='email'>Email</label>
            <input
              type='email'
              id='email'
              name='email'
              value={formData.email}
              onChange={handleChange}
              autoComplete='email'
              className={validationErrors.email ? 'input-error' : ''}
            />
            {validationErrors.email && (
              <p className='error-message'>{validationErrors.email}</p>
            )}
          </div>
          <div className='c-form-group'>
            <label htmlFor='phoneNumber'>Phone Number</label>
            <input
              type='tel'
              id='phoneNumber'
              name='phone'
              value={formData.phone}
              onChange={handleChange}
              autoComplete='tel-national'
              className={validationErrors.phoneNumber ? 'input-error' : ''}
            />
            {validationErrors.phoneNumber && (
              <p className='error-message'>{validationErrors.phoneNumber}</p>
            )}
          </div>
        </div>
        <div className='c-form-row'>
          <div className='c-form-group'>
            <label htmlFor='city'>City</label>
            <input
              type='text'
              id='city'
              name='city'
              value={formData.city}
              onChange={handleChange}
              autoComplete='address-level2'
            />
          </div>
          <div className='c-form-group'>
            <label htmlFor='state'>State</label>
            <select
              id='state'
              name='state'
              value={formData.state}
              onChange={handleChange}
              autoComplete='address-level1'
            >
              {states.map((state) => (
                <option key={state.abbreviation} value={state.abbreviation}>
                  {state.abbreviation}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div className='c-form-group'>
          <label htmlFor='position'>Position You Are Applying For</label>
          <input
            type='text'
            id='position'
            name='position'
            value={formData.position}
            onChange={handleChange}
            autoComplete='off'
          />
        </div>
        <div className='c-form-group'>
          <label htmlFor='years_experience'>
            How many years of outside sales experience do you have?
          </label>
          <input
            type='text'
            id='question-1'
            name='years_experience'
            value={formData.years_experience}
            onChange={handleChange}
            autoComplete='off'
          />
        </div>
        <div className='c-form-group'>
          <label htmlFor='sales_style'>
            How would you describe your sales style?
          </label>
          <input
            type='text'
            id='question-2'
            name='sales_style'
            value={formData.sales_style}
            onChange={handleChange}
            autoComplete='off'
          />
        </div>
        <div className='c-form-group'>
          <label htmlFor='question-a'>
            Have you ever sold to physicians or hospitals?
          </label>
          <input
            type='text'
            id='question-a'
            name='question_a'
            value={formData.question_a}
            onChange={handleChange}
            autoComplete='off'
          />
        </div>
        <div className='c-form-group'>
          <label htmlFor='question-b'>
            In a medical office, how do you get past the gatekeeper?
          </label>
          <input
            type='text'
            id='question-b'
            name='question_b'
            value={formData.question_b}
            onChange={handleChange}
            autoComplete='off'
          />
        </div>
        <div className='c-form-group'>
          <label htmlFor='message'>Message (optional)</label>
          <textarea
            id='message'
            name='message'
            value={formData.message}
            onChange={handleChange}
            autoComplete='off'
          ></textarea>
        </div>
        <button type='submit' className='btn primary-btn'>
          {buttonText}
        </button>
        {successMessage && <p className='success-message'>{successMessage}</p>}
        {errorMessage && <p className='error-message'>{errorMessage}</p>}
      </form>
    </div>
  );
};

export default CareerContact;
