import './App.css';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import Navigation from './components/Navigation/Navigation';
import Home from './pages/Home';
import About from './pages/About';
import TestDetails from './pages/TestDetails';
import Contact from './pages/Contact';
import Careers from './pages/Careers';
import NotFound from './pages/NotFound';
import Footer from './components/Footer/Footer';
import Scroll from './components/Scroll';

function App() {
  return (
    <div className='App'>
      <Router>
        <Navigation />
        <Scroll />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<About />} />
          <Route path='/contact' element={<Contact />} />
          <Route path='/testinfo/:testId' element={<TestDetails />} />
          <Route path='/careers' element={<Careers />} />
          <Route path='*' element={<NotFound / >} /> {/* catch all undefined routes */}
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
