import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { slideIn } from '../variants';
import Hero from '../components/hero/Hero';
import TestCard from '../components/cards/TestCard';
import GenContactForm from '../components/Forms/GenContactForm';
import StatCard from '../components/cards/StatCard';

const Home = () => {
  return (
    <div className='main-container'>
      <div className='hero-wrapper'>
        <Hero />
      </div>

      <div className='services-section'>
        <motion.div
          variants={slideIn('up', 0.1)}
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true }}
          className='services-header'
        >
          <h1 className='section-header mb-3'>Our Services</h1>
          <h2 className='section-subheader mb-3'>
            We offer a wide range of diagnostic testing services.
          </h2>
        </motion.div>
        <div className='test-card-section '>
          <TestCard />
        </div>
      </div>

      <div className='about-wrapper'>
        <div className='about-section'>
          <motion.div
            variants={slideIn('up', 0.1)}
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true }}
            className='about-header'
          >
            <h1 className='section-header mb-3'>Benefits of PCR Testing</h1>
          </motion.div>
          <div className='about-info-container'>
            <div className='about-content'>
              <video
                autoPlay
                muted
                playsInline
                loop
                preload='auto'
                className='about-video'
              >
                <source
                  src='https://res.cloudinary.com/dh8uixj4v/video/upload/v1720212127/shutterstock_1063037656_shxpw8.mov'
                  type='video/mp4'
                />
              </video>
              <div className='about-text'>
                <motion.h1
                  variants={slideIn('up', 0.1)}
                  initial='hidden'
                  whileInView='visible'
                  viewport={{ once: true }}
                  className='body-header mb-3'
                >
                  Quick Reliable Results.
                </motion.h1>
                <motion.p
                  variants={slideIn('up', 0.1)}
                  initial='hidden'
                  whileInView='visible'
                  viewport={{ once: true }}
                  className='text-body text-primary'
                >
                  Molecular PCR testing offers significant benefits due to its
                  high accuracy and rapid results. By detecting the genetic
                  material of pathogens, this advanced method ensures precise
                  identification of infections, even in cases where traditional
                  tests might fail. The quick turnaround time for results means
                  that healthcare providers can make informed treatment
                  decisions faster, improving patient outcomes. Additionally,
                  the sensitivity and specificity of PCR testing minimize the
                  chances of false positives or negatives, providing reliable
                  data for effective treatment planning.
                </motion.p>

                <div className='btn-container'>
                  <Link to='/about' className='secondary-btn btn'>
                    Learn More
                  </Link>
                  <Link to='/contact' className='primary-btn btn'>
                    Get Started Today
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='benefits-section '>
        <div className='benefits-header'>
          <motion.h1
            variants={slideIn('up', 0.1)}
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true }}
            className='b-main-header'
          >
            Why Choose PCR?
          </motion.h1>
        </div>
        <StatCard />
      </div>

      <div className='cta-section'>
        <div className='cta-grid-item '>
          <motion.h1
            variants={slideIn('up', 0.1)}
            initial='hidden'
            whileInView='visible'
            viewport={{ once: true }}
            className='cta-header'
          >
            Discover Our PCR Tests
          </motion.h1>
        </div>
        <div className='cta-grid-item cta-content'>
          <h2 className='cta-subheader mb-3'>
            Transform your healthcare strategy with the reliability of PCR
            testing.
          </h2>
          <div className='cta-btn-container'>
            <Link to='#test-card-section' className='btn secondary-btn'>
              Learn More
            </Link>
            <Link to='/contact' className='btn primary-btn'>
              Get Started Today
            </Link>
          </div>
        </div>
      </div>

      <div className='contact-section'>
        <GenContactForm />
      </div>
    </div>
  );
};

export default Home;
