import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import './nav.styles.css';
import { FiX, FiChevronDown } from 'react-icons/fi';

const MobileNav = ({ isOpen, toggleMenu }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleNavItemClick = () => {
    setDropdownOpen(false);
    toggleMenu();
  };

  return (
    <div className={`mobile-nav ${isOpen ? 'open' : ''}`}>
      <button className='close-btn' onClick={toggleMenu}>
        <FiX size={30} />
      </button>
      <ul className='mobile-nav-list'>
        <li>
          <NavLink to='/' className='nav-item' onClick={handleNavItemClick}>
            Home
          </NavLink>
        </li>
        <li>
          <NavLink
            to='/about'
            className='nav-item'
            onClick={handleNavItemClick}
          >
            About
          </NavLink>
        </li>
        <li
          className={`mobile-dropdown ${dropdownOpen ? 'open' : ''}`}
          onClick={toggleDropdown}
        >
          <div className='nav-item'>
            Test Menu <FiChevronDown className='arrow-icon' />
          </div>
          <ul className={`mobile-dropdown-menu ${dropdownOpen ? 'open' : ''}`}>
            <li className='mobile-dropdown-item'>
              <NavLink
                to='/testinfo/1'
                className='nav-link'
                onClick={handleNavItemClick}
              >
                UTI PCR Testing
              </NavLink>
            </li>
            <li className='mobile-dropdown-item'>
              <NavLink
                to='/testinfo/2'
                className='nav-link'
                onClick={handleNavItemClick}
              >
                Respiratory PCR Testing
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <NavLink
            to='/contact'
            className='nav-item'
            onClick={handleNavItemClick}
          >
            Contact
          </NavLink>
        </li>
        <li>
          <NavLink
            to='/careers'
            className='nav-item'
            onClick={handleNavItemClick}
          >
            Careers
          </NavLink>
        </li>
        <li>
          <a
            href='https://sierragenomics.labnexus.net'
            target='_blank'
            rel='noopener noreferrer'
            className='nav-item'
            onClick={handleNavItemClick}
          >
            Results Portal
          </a>
        </li>
      </ul>
    </div>
  );
};

export default MobileNav;
