import React, { useState } from 'react';
import { motion } from 'framer-motion';
import { slideIn, slideInFromMiddle } from '../variants';
import '../PageStyles/Contact.styles.css';
import { FiMail, FiPhone, FiMapPin } from 'react-icons/fi';
import emailjs from '@emailjs/browser';

const Contact = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    practiceName: '',
    phone: '',
    email: '',
    message: '',
  });

  const [statusMessage, setStatusMessage] = useState('');
  const [validationErrors, setValidationErrors] = useState({});
  const [buttonText, setButtonText] = useState('Submit');

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const validateForm = () => {
    const errors = {};

    // Email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailPattern.test(formData.email)) {
      errors.email = 'Please enter a valid email address';
    }

    // Phone number validation: Ensure it has 10 digits
    const cleanedPhone = formData.phone.replace(/\D/g, '');
    if (cleanedPhone.length !== 10) {
      errors.phone = 'Phone number must be 10 digits';
    }

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    setButtonText('Sending...');

    emailjs
      .sendForm(
        'service_vuasfxq',
        'template_w8ynvq8',
        e.target,
        'U4mqa74Td0Ym-Q2Y7'
      )
      .then(
        (result) => {
          setButtonText('Sent');
          setStatusMessage(
            "Thanks for your submission, we'll get back to you shortly."
          );
        },
        (error) => {
          setButtonText('Submit');
          setStatusMessage(
            'There was an error submitting your form, please try again.'
          );
        }
      );

    e.target.reset();
  };

  return (
    <div className='main-container'>
      <div className='contact-page-wrapper'>
        <div className='contact-img'>
          <img
            src='https://res.cloudinary.com/dh8uixj4v/image/upload/v1721526462/shutterstock_2049251393_1_pjhkwx.jpg'
            alt='description'
            className='contact-image'
            loading='lazy'
          />
        </div>
        <div className='main-contact-container'>
          <motion.div 
          variants={slideInFromMiddle(0.1)}
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true }}
          className='main-contact-header'>
            <h1>Contact us</h1>
            <h2 className='main-contact-subheader'>
              Have any questions or interested in our services? 

              Our Team Is
              Ready to Help!
            </h2>
          </motion.div>
          <motion.div 
          variants={slideIn('up', 0.1)}
          initial='hidden'
          whileInView='visible'
          viewport={{ once: true }}
          className='main-contact-body'>
            <form className='contact-form' onSubmit={handleSubmit}>
              <div className='form-row'>
                <label>
                  First Name
                  <input
                    type='text'
                    name='firstName'
                    autoComplete='given-name'
                    value={formData.firstName}
                    onChange={handleChange}
                    required
                  />
                </label>
                <label>
                  Last Name
                  <input
                    type='text'
                    name='lastName'
                    autoComplete='family-name'
                    value={formData.lastName}
                    onChange={handleChange}
                    required
                  />
                </label>
              </div>
              <label>
                Practice Name
                <input
                  type='text'
                  name='practiceName'
                  autoComplete='organization-title'
                  value={formData.practiceName}
                  onChange={handleChange}
                />
              </label>
              <label>
                Phone
                {validationErrors.phone && (
                  <p className='error-message'>{validationErrors.phone}</p>
                )}
                <input
                  type='tel'
                  name='phone'
                  autoComplete='tel-national'
                  value={formData.phone}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                Email
                {validationErrors.email && (
                  <p className='error-message'>{validationErrors.email}</p>
                )}
                <input
                  type='email'
                  name='email'
                  autoComplete='email'
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </label>
              <label>
                Message
                <textarea
                  name='message'
                  value={formData.message}
                  onChange={handleChange}
                  required
                ></textarea>
              </label>

              <button type='submit' className='primary-btn btn'>
                {buttonText}
              </button>
              {statusMessage && (
                <p className='status-message'>{statusMessage}</p>
              )}
            </form>
            <div className='maincontact-info'>
              <div className='main-contact-info-item'>
                <FiMail className='contact-icon' />
                <div>
                  <h2>Email</h2>
                  <p>contactus@sierra-genomics.com</p>
                </div>
              </div>
              <div className='contact-info-item'>
                <FiPhone className='contact-icon' />
                <div>
                  <h2>Phone</h2>
                  <p>+1 (555) 000-0000</p>
                </div>
              </div>
              <div className='contact-info-item'>
                <FiMapPin className='contact-icon' />
                <div>
                  <h2>Address</h2>
                  <p>6500 Greenville Ave Suite 640</p>
                  <p>Dallas, TX 75206</p>
                </div>
              </div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
