import React from 'react';
import { Link } from 'react-router-dom';
import testData from '../../assets/TestData';
import './testCard.styles.css';

const TestCard = () => {
  return (
    <div className='card-container'>
      {testData.map((test) => (
        <Link to={`/testinfo/${test.id}`} className='test-card' key={test.id}>
          <div className='card-img'>
            <img src={test.thumbnailImg} alt={test.title} className='sqr-img' />
          </div>
          <div className='card-content'>
            <h1 className='card-header'>{test.title}</h1>
            <p className='card-body'>{test.summary}</p>
            <div className='card-btn'>Learn More</div>
          </div>
        </Link>
      ))}
    </div>
  );
};

export default TestCard;
