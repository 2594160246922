import React from 'react';
import { Link } from 'react-router-dom';
import '../PageStyles/NotFound.styles.css';

const NotFound = () => {
  return (
    <div className='not-found-container'>
      <h1 className='not-found-title'>404: Page Not Found</h1>
      <Link to='/' className='home-button'>
        Back Home
      </Link>
    </div>
  );
};

export default NotFound;
