import React from 'react';
import { Link } from 'react-router-dom';
import { motion } from 'framer-motion';
import { slideIn } from '../../variants';

const Hero = () => {
  const images = [
    {
      src: 'https://res.cloudinary.com/dh8uixj4v/image/upload/v1720208003/shutterstock_2150221735_coge4x.jpg',
      alt: 'Researcher working in a laboratory 1',
    },
    {
      src: 'https://res.cloudinary.com/dh8uixj4v/image/upload/v1720211557/shutterstock_2312846731_siufjz.jpg',
      alt: 'Researcher working in a laboratory 2',
    },
    {
      src: 'https://res.cloudinary.com/dh8uixj4v/image/upload/v1720071821/shutterstock_2374875085_1_yyjsdm.jpg',
      alt: 'Researcher working in a laboratory 3',
    },
    {
      src: 'https://res.cloudinary.com/dh8uixj4v/image/upload/v1720209823/shutterstock_2026087052_pu1sby.jpg',
      alt: 'Researcher working in a laboratory 4',
    },
  ];

  return (
    <div className='hero-container'>
      <div className='hero-content'>
        <motion.h1 
        variants={slideIn('right', 0.1)}
        initial='hidden'
        whileInView='visible'
        viewport={{once: true}}
        className='hero-heading'>Innovating Health One Test at a Time</motion.h1>

        <motion.h2 
        variants={slideIn('up', 0.15)}
        initial='hidden'
        whileInView='visible'
        viewport={{once: true}}
        className='hero-description'>
          Setting new standards in healthcare with our fast and accurate
          diagnostic testing. Experience the power of fast, precise results for
          a healthier tomorrow.
        </motion.h2>
        <div className='hero-buttons'>
          <Link to='/about' className='secondary-btn btn'>
            Learn More
          </Link>
          <Link to='/contact' className='primary-btn btn'>
            Contact Us
          </Link>
        </div>
      </div>
      <div className='hero-images'>
        <div className='hero-image-column'>
          <img
            src={images[0].src}
            alt={images[0].alt}
            className='grid-img'
            loading='lazy'
          />
          <img
            src={images[1].src}
            alt={images[1].alt}
            className='grid-img'
            loading='lazy'
          />
        </div>
        <div className='hero-image-column offset'>
          <img
            src={images[2].src}
            alt={images[2].alt}
            className='grid-img'
            loading='lazy'
          />
          <img
            src={images[3].src}
            alt={images[3].alt}
            className='grid-img'
            loading='lazy'
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
